import Footer from './global-components/footer';
import NavBar from './global-components/navbar';
import PageHeader from './global-components/pageheader';
import GetQuote from './sectional-components/getQuote';
import Map from './sectional-components/map';

const ContactUsPage = () => {
	return (
		<div>
			<NavBar />
			{/* <PageHeader pagetitle="Contact Us" /> */}
			<GetQuote />
			<Map />
			<Footer />
		</div>
	);
};

export default ContactUsPage;
