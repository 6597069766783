const BrandsTestimonials = () => {
	return (
		<section className='sec-block why-choose-us'>
			<div className='fixed-bg '></div>
			<h2
				style={{ color: 'black				' }}
				className='page-number'
			>
				04
			</h2>
			<div
				className='container'
				style={{
					marginTop: '-85px',
					marginBottom: '120px',
				}}
			>
				<div className='row'>
					<div className='col-lg-5'>
						<div className='counter-section'>
							<div className='row'>
								<div className='col-lg-6 col-md-6 col-sm-6 col-12'>
									<div
										className='counter-div gradient-bg1 wow fadeInUp'
										data-wow-delay='300ms'
									>
										<img
											style={{ height: '150px' }}
											src='images/brands_01.png'
											alt='Equitrail Global Logistics Client'
										/>
									</div>
								</div>
								<div className='col-lg-6 col-md-6 col-sm-6 col-12'>
									<div
										className='counter-div scnd-dv gradient-bg1 wow fadeInUp'
										data-wow-delay='600ms'
									>
										<img
											style={{ height: '150px' }}
											src='images/brands_02.png'
											alt='Equitrail Global Logistics Client'
										/>
									</div>
								</div>
								<div className='col-lg-6 col-md-6 col-sm-6 col-12 mt-5'>
									<div
										className='counter-div gradient-bg2 wow fadeInUp'
										data-wow-delay='900ms'
									>
										<img
											style={{ height: '150px' }}
											src='images/brands_03.png'
											alt='Equitrail Global Logistics Client'
										/>
									</div>
								</div>
								<div className='col-lg-6 col-md-6 col-sm-6 col-12 mt-5'>
									<div
										className='counter-div scnd-dv gradient-bg1 wow fadeInUp'
										data-wow-delay='900ms'
									>
										<img
											style={{ height: '150px' }}
											src='images/brands_04.png'
											alt='Equitrail Global Logistics Client'
										/>
									</div>
								</div>
								<div className='col-lg-6 col-md-6 col-sm-6 col-12 mt-5'>
									<div
										className='counter-div gradient-bg2 wow fadeInUp'
										data-wow-delay='900ms'
									>
										<img
											style={{ height: '150px' }}
											src='images/brands_05.png'
											alt='Equitrail Global Logistics Client'
										/>
									</div>
								</div>
								<div className='col-lg-6 col-md-6 col-sm-6 col-12 mt-5'>
									<div
										className='counter-div scnd-dv gradient-bg1 wow fadeInUp'
										data-wow-delay='900ms'
									>
										<img
											style={{ height: '150px' }}
											src='images/brands_06.png'
											alt='Equitrail Global Logistics Client'
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className='col-lg-7'>
						<div className='why-we-sec'>
							<div
								className='main-banner-text title-hd wow fadeInUp'
								data-wow-delay='300ms'
							>
								<span>a few words</span>
								<h1
									style={{
										color: 'black',
									}}
								>
									Why choose us? <span></span>
								</h1>
								<p style={{ color: '#2c368d' }}>
									Our high level of expertise and professionalism have made us
									the preferred company for many South Africans and Zimbabweans.
									We deliver to various locations:
								</p>
							</div>

							<ul
								className='our-features-list wow fadeInDown'
								data-wow-delay='600ms'
							>
								<li>
									<h3 style={{ color: '#2c368d' }}>Mozambique</h3>
								</li>
								<li>
									<h3 style={{ color: '#2c368d' }}>South Africa</h3>
								</li>
								<li>
									<h3 style={{ color: '#2c368d' }}>Zambia</h3>
								</li>
								<li>
									<h3 style={{ color: '#2c368d' }}>Zimbabwe</h3>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default BrandsTestimonials;
