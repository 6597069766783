const MiniAbout = () => {
	return (
		<section className="sec-block">
			<h2
				style={{ color: 'black' }}
				className="page-number bottom">
				02.
			</h2>
			<div className="bg-position left-position"></div>
			<div className="container">
				<div className="expt-text float-right">
					<div
						className="main-banner-text title-hd wow slideInRight"
						data-wow-delay="300ms">
						<span>about us</span>
						<h1 style={{ color: 'black' }}>
							We are Experts in Our Field <span></span>
						</h1>
						<p>
							We focus on strategic logistical solutions and being recognized
							for maximizing industry value creation.
						</p>
						<p className="bdy">
							Since our foundation, we have built a strong network of
							international suppliers, and we can recommend the right supplier
							for you based on our database. And our highly qualified and
							experienced team will make sure you get exactly what you want on
							time and at a reasonable price.
						</p>
						<a
							href="about.html"
							title=""
							className="btn-default2">
							read more
						</a>
					</div>
				</div>

				<div className="clearfix"></div>
			</div>
		</section>
	);
};

export default MiniAbout;
