const Map = () => {
	return (
		<section className="map-section">
			<iframe
				src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14332.29023788549!2d28.21032!3d-26.09655!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e9514a197cdb505%3A0xb612ea00da9f5751!2sEquitrail%20Global%20Freight%20Logistics!5e0!3m2!1sen!2szw!4v1677085625776!5m2!1sen!2szw"
				width="1920"
				height="600"
				style={{ border: 0 }}
				allowFullScreen=""
				loading="lazy"
				referrerPolicy="no-referrer-when-downgrade"></iframe>
		</section>
	);
};

export default Map;
