import Footer from './global-components/footer';
import NavBar from './global-components/navbar';
// import PageHeader from './global-components/pageheader';
import About from './sectional-components/about';
// import Brands from './sectional-components/brands';

const AboutPage = () => {
	return (
		<div>
			<NavBar />
			{/* <PageHeader pagetitle="About Us" /> */}
			<About />
			{/* <Brands /> */}
			<Footer />
		</div>
	);
};

export default AboutPage;
