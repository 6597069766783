const MainBanner = () => {
	return (
		<section className="main-banner">
			<div className="banner-slider">
				<div className="banner-slide">
					<div className="container">
						<div className="main-banner-text">
							<span
								className="wow fadeInUp"
								data-wow-delay="300ms">
								The best service.
							</span>
							<h1
								className="wow fadeInUp"
								data-wow-delay="450ms">
								Moving cargo across Southern Africa<span></span>
							</h1>
							<p
								className="wow fadeInUp"
								data-wow-delay="600ms"
								style={{ color: 'black' }}>
								Need help with procurement, freight forwarding, custom clearing
								and distribution in Southern Africa?
							</p>
							<div
								className="play-video-div wow fadeInUp"
								data-wow-delay="800ms">
								<div className="poly1">
									<div className="poly2">
										<a
											target="_blank"
											href="https://www.facebook.com/equitrailglobal/videos/336868228646650"
											title="Equitrail Global Services Video"
											className="play-video html5lightbox">
											<img
												src="images/icon1.svg"
												alt="What Equiitrail Global Logistics does."
											/>
										</a>
									</div>
								</div>
								<div className="pl-text">
									<h3 style={{ color: 'black' }}>About Us</h3>
									{/* <span style={{ color: 'black' }}>Promotion video</span> */}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default MainBanner;
