import { useParams } from 'react-router-dom';

const ServiceDetails = () => {
	let params = useParams();

	console.log(params.service);

	let freight = false;
	let procurement = false;
	let forwarding = false;
	let customs = false;
	let warehousing = false;

	if (params.service === 'freight') freight = true;
	if (params.service === 'procurement') procurement = true;
	if (params.service === 'forwarding') forwarding = true;
	if (params.service === 'customs') customs = true;
	if (params.service === 'warehousing') warehousing = true;
	return (
		<div>
			<section
				style={{ marginTop: '200px' }}
				className="pager-sec text-right pb-75 dark-bg">
				<div className="container-fluid">
					<ul>
						<li>
							<a
								href="/"
								title="">
								Home
							</a>
						</li>
						<li>
							<a
								href="services"
								title="">
								Services
							</a>
						</li>
						<li>
							<span>{params.service}</span>
						</li>
					</ul>
				</div>
			</section>
			<section className="sec-block p-0">
				<div className="fixed-bg dark-bg"></div>
				<div className="page-content">
					<div className="fixed-bg bg9"></div>
					<h2 className="page-number">01.</h2>
					{freight && (
						<div className="container">
							<div className="main-banner-text title-hd max-width-80">
								<span>our services</span>
								<h1>
									Road Frieght <span></span>
								</h1>
							</div>
							<div className="service-details pb-0">
								<div className="row">
									<div className="col-lg-9">
										<div className="service-details-content">
											<img
												src={`/images/services-${params.service}.png`}
												alt=""
												className="img-fluid"
											/>
											<p>
												Our road transportation services enable:
												<br /> • Flexibility and reliability supported up by a
												national footprint and up-to-date fleet which improves
												brand reputation
												<br /> • Total risk reduction and improved safety
												standards
												<br /> • Solutions that are modified and
												<br /> • achieved to your precise requirements <br /> •
												Access to our intellectual capital gained and achieved
												through experience in transportation Our packages range
												from 0 - 30 ton for a single load. <br />
												<br />
												We offer:
												<br /> • International road freight We offer road
												freight service across South Africa and Zimbabwean
												boarder. Delivery guaranteed within a week.
												<br /> • Domestic road freight We offer road freight
												services all over Zimbabwe and South Africa Delivery
												guaranteed within 24hours. We offer courier services in:
												Zimbabwe – Harare, Bulawayo and all cities and towns
												surrounding and or along the route South Africa –
												Johannesburg and all cities surrounding and or along the
												route We also offer collection of goods upon request or
												alternatively you can deliver to our premises. Our fleet
												includes:
												<br /> • Bakkies These handle loads of up to 4 and half
												ton. Also convenient for all door-to-door deliveries •
												Rigid trucks We have fleet of rigid trucks for loads u
												to 7 ton
												<br /> • Refrigerated trucks We provide refrigerated
												trucks for cargo whose nature requires temperature
												control
												<br /> • Haulage trucks We have a fleet of heavy-duty
												trucks for loads up to 34 ton To meet the increased
												demand, the company has acquired cutting-edge trailers.
												We also collaborate with a number of logistics service
												providers to ensure that all types of goods are
												transported.
											</p>
										</div>
									</div>
									<div className="col-lg-3">
										<div className="sidebar">
											<div className="widget widget-help">
												<h3>How can we help you?</h3>
												<p>Don’t know where to start? We’ll help you.</p>
												<a
													href="/contact"
													title=""
													className="btn-default2">
													contact us
												</a>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					)}
					{procurement && (
						<div className="container">
							<div className="main-banner-text title-hd max-width-80">
								<span>our services</span>
								<h1>
									Procurement <span></span>
								</h1>
							</div>
							<div className="service-details pb-0">
								<div className="row">
									<div className="col-lg-9">
										<div className="service-details-content">
											<img
												src={`/images/services-${params.service}.png`}
												alt=""
												className="img-fluid"
											/>
											<p>
												Our procurement process provides a cost-effective
												solution as well as process simplification. We have
												formed strategic partnerships with significant
												suppliers, which allows us to provide cost-effective,
												on-time delivery, transportation visibility, reduced
												risk, and improved operational efficiency. Because VAT
												claims can be a hassle, we thought it would be a good
												idea to offer this service to our customers who want to
												buy goods in South Africa. Our procurement team follows
												a process that includes the following steps: <br />
												<br />
												1. Examination: Listen to and collect information on the
												entire picture - sourcing, procurement, import, export,
												pick-up, delivery, storage, and customs clearance.
												<br /> 2. Evaluation: Assess the effectiveness,
												value-for-money, and suitability of current service
												provision and processes.
												<br /> 3. Endorsements: Make objective recommendations
												on how to improve the processes. This could include
												recommending different suppliers, providing cost/price
												comparisons, and/or tailoring a solution to the
												individual needs.
												<br />
												<br /> The procedure will provide a balanced
												perspective, including the benefits and drawbacks of the
												various approaches, leaving the client with a full
												understanding of their options and the ability to make
												more informed decisions. We provide procurement services
												for goods ranging from groceries, home furnishings, and
												kitchen appliances to mining equipment, raw materials,
												and so on.
											</p>
										</div>
									</div>
									<div className="col-lg-3">
										<div className="sidebar">
											<div className="widget widget-help">
												<h3>How can we help you?</h3>
												<p>Don’t know where to start? We’ll help you.</p>
												<a
													href="/contact"
													title=""
													className="btn-default2">
													contact us
												</a>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					)}
					{forwarding && (
						<div className="container">
							<div className="main-banner-text title-hd max-width-80">
								<span>our services</span>
								<h1>
									Freight Forwarding <span></span>
								</h1>
							</div>
							<div className="service-details pb-0">
								<div className="row">
									<div className="col-lg-9">
										<div className="service-details-content">
											<img
												src={`/images/services-${params.service}.png`}
												alt=""
												className="img-fluid"
											/>
											<p>
												We have the capacity to handle all of your professional
												freight forwarding needs. We provide hassle-free border
												crossing services between the following countries:{' '}
												<br />
												<br />• South Africa <br />• Zimbabwe <br />• Zambia{' '}
												<br />• Mozambique
											</p>
										</div>
									</div>
									<div className="col-lg-3">
										<div className="sidebar">
											<div className="widget widget-help">
												<h3>How can we help you?</h3>
												<p>Don’t know where to start? We’ll help you.</p>
												<a
													href="/contact"
													title=""
													className="btn-default2">
													contact us
												</a>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					)}
					{customs && (
						<div className="container">
							<div className="main-banner-text title-hd max-width-80">
								<span>our services</span>
								<h1>
									Freight Forwarding <span></span>
								</h1>
							</div>
							<div className="service-details pb-0">
								<div className="row">
									<div className="col-lg-9">
										<div className="service-details-content">
											<img
												src={`/images/services-${params.service}.png`}
												alt=""
												className="img-fluid"
											/>
											<p>
												Equitrail Global is a ZIMRA and SARS-accredited clearing
												house. We have a team of professionals who are prepared
												to handle the complex technical aspects of customs
												clearance procedures for both imports and exports of
												cargo in Zimbabwe and South Africa. <br /> <br /> Our
												agents will assist you in preparing and submitting
												documents required to facilitate exports or imports,
												representing clients during cross examination,
												assessment, payment of duty, and taking delivery of
												cargo from customs after clearance, along with
												documents. <br />
												<br />
												We offer clearing services to retailers, individuals,
												small businesses, large businesses, and manufacturers.
											</p>
										</div>
									</div>
									<div className="col-lg-3">
										<div className="sidebar">
											<div className="widget widget-help">
												<h3>How can we help you?</h3>
												<p>Don’t know where to start? We’ll help you.</p>
												<a
													href="/contact"
													title=""
													className="btn-default2">
													contact us
												</a>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					)}
					{warehousing && (
						<div className="container">
							<div className="main-banner-text title-hd max-width-80">
								<span>our services</span>
								<h1>
									Warehousing and Distribution <span></span>
								</h1>
							</div>
							<div className="service-details pb-0">
								<div className="row">
									<div className="col-lg-9">
										<div className="service-details-content">
											<img
												src={`/images/services-${params.service}.png`}
												alt=""
												className="img-fluid"
											/>
											<p>
												Our warehouse solutions are adaptable to the transport
												methods chosen by our clients. Our solution includes an
												improved warehouse design with a variety of systems and
												processes such as racking, pick and pack, and track and
												trace capabilities. Equitrail Global has distribution
												centers in South Africa and Zimbabwe. We provide: <br />
												<br />• Secure short-term storage prior to transport{' '}
												<br />• Long-term storage facility awaiting distribution
											</p>
										</div>
									</div>
									<div className="col-lg-3">
										<div className="sidebar">
											<div className="widget widget-help">
												<h3>How can we help you?</h3>
												<p>Don’t know where to start? We’ll help you.</p>
												<a
													href="/contact"
													title=""
													className="btn-default2">
													contact us
												</a>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					)}
				</div>
			</section>
		</div>
	);
};

export default ServiceDetails;
