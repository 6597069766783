import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const Footer = () => {
	const [values, setValues] = useState({
		home: false,
		about: false,
		services: false,
		contact: false,
	});

	const location = useLocation().pathname;

	const sortTestimonial = () => {
		if (location === '/') setValues({ ...values, home: true });
		if (location === '/about') setValues({ ...values, about: true });
		if (location === '/services') setValues({ ...values, services: true });
		if (location === '/contact') setValues({ ...values, contact: true });
	};

	useEffect(() => {
		sortTestimonial();
	}, []);

	return (
		<footer>
			<div className='container'>
				<div className='top-footer'>
					<div className='row'>
						<div className='col-lg-6'>
							<div
								className='tp-contact wow fadeInUp'
								data-wow-delay='300ms'
							>
								<div className='main-banner-text title-hd'>
									<span>newsletter</span>
									<h1 style={{ color: 'white' }}>
										We connect <br />
										you <span></span>
									</h1>
									<p
										style={{ color: 'white' }}
										className='bdy'
									>
										We are grateful to know you are interested in our content
										and services. Stay up to date.
									</p>
								</div>

								<form className='newsletter-form'>
									<input
										type='email'
										name='email'
										placeholder='Email'
									/>
									<button type='submit'>
										<img
											src='images/send.svg'
											alt=''
										/>
									</button>
								</form>
								<div className='clearfix'></div>
							</div>
						</div>
						{values.home && (
							<div className='col-lg-6'>
								<div
									className='testimonial-sec wow fadeInDown'
									data-wow-delay='300ms'
								>
									<div className='testi-head'>
										<div className='testi-info'>
											<h3>Munyaradzi Mutambi</h3>
											<span>Zimplow, Holdings Limited</span>
										</div>
									</div>
									<p>
										Since they first opened their doors, I've been doing
										business with Equitrail Global Freight Logistics. The
										employees are fantastic! Very helpful and considerate with a
										sense of urgency. Loads are delivered on time, and the
										charges are very reasonable. I want to continue doing
										business with them for a long time.
									</p>
								</div>

								<div className='clearfix'></div>
							</div>
						)}
						{values.about && (
							<div className='col-lg-6'>
								<div
									className='testimonial-sec wow fadeInDown'
									data-wow-delay='300ms'
								>
									<div className='testi-head'>
										<div className='testi-info'>
											<h3>Andy Kemp</h3>
											<span>Mine Pro Zimbabwe</span>
										</div>
									</div>
									<p>
										I wanted to take a moment to express my gratitude to you and
										your team. Since we started working together, I've been
										extremely delighted. Your team's responsiveness and
										follow-through has been outstanding, in my opinion. I've
										grown accustomed to relying on the Equitrail Global Freight
										Logistics
									</p>
								</div>

								<div className='clearfix'></div>
							</div>
						)}
						{values.services && (
							<div className='col-lg-6'>
								<div
									className='testimonial-sec wow fadeInDown'
									data-wow-delay='300ms'
								>
									<div className='testi-head'>
										<div className='testi-info'>
											<h3>Denzil J. Marsh</h3>
											<span>Commercial and Industrial Holdings</span>
										</div>
									</div>
									<p>
										Equitrail Global's collaborative approach to our company
										demands struck me much. They just outperform all of my
										transporters in terms of execution and customer service.
									</p>
								</div>

								<div className='clearfix'></div>
							</div>
						)}
						{values.contact && (
							<div className='col-lg-6'>
								<div
									className='testimonial-sec wow fadeInDown'
									data-wow-delay='300ms'
								>
									<div className='testi-head'>
										<div className='testi-info'>
											<h3>Ross Leach</h3>
											<span>Flexco South Africa</span>
										</div>
									</div>
									<p>
										We've had delays with shipments in the past, as well as
										issues with goods being damaged. However, since working with
										Equitrail Global, we have received our shipments on time and
										have reduced damage to less than 1%.
									</p>
								</div>

								<div className='clearfix'></div>
							</div>
						)}
					</div>
				</div>

				<div className='bottom-footer'>
					<div className='row'>
						<div className='col-lg-3 col-md-6 col-sm-6 col-12'>
							<div
								className='widget widget-about wow fadeInUp'
								data-wow-delay='300ms'
							>
								<img
									style={{
										width: '250px',
									}}
									src='images/logo-white.svg'
									alt=''
								/>
								<p>© 2023 Equitrail Global Freight Logistics.</p>
							</div>
						</div>
						<div className='col-lg-3 col-md-6 col-sm-6 col-12'>
							<div
								className='widget widget-para wow fadeInDown'
								data-wow-delay='600ms'
							>
								<h3 className='widget-title'>Working hours:</h3>
								<p>
									Mon - Sat: 9 am - 6 pm, <br />
									Sun: Holiday
								</p>
							</div>
						</div>
						<div className='col-lg-3 col-md-6 col-sm-6 col-12'>
							<div
								className='widget widget-para wow fadeInUp'
								data-wow-delay='900ms'
							>
								<h3 className='widget-title'>Address:</h3>
								<p>
									Equitrail Global Freight Logistics 3 Lantana Lane Edleen
									Johannesburg South Africa
								</p>
							</div>
						</div>
						<div className='col-lg-3 col-md-6 col-sm-6 col-12'>
							<div
								className='widget widget-para wow fadeInDown'
								data-wow-delay='1200ms'
							>
								<h3 className='widget-title'>Hit us up:</h3>
								<span>
									+263 292 71206 <br />
									+263 780 630 097
								</span>
								<br />
								<a
									style={{ color: 'white' }}
									href='mailto:admin@equitrailglobal.co.za'
								>
									admin@equitrailglobal.co.za
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
