import Footer from './global-components/footer';
import NavBar from './global-components/navbar';
import PageHeader from './global-components/pageheader';
import Services from './sectional-components/services';

function ServicesPage() {
	return (
		<div>
			<NavBar />
			<PageHeader pagetitle="Services" />
			<Services />
			<Footer />
		</div>
	);
}

export default ServicesPage;
