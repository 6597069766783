const About = () => {
	return (
		<section
			className='about_us_section'
			style={{ paddingTop: '150px' }}
		>
			<div className='page-content'>
				<div className='fixed-bg bg7'></div>
				<h2 className='page-number'>01.</h2>
				<div className='container'>
					<img
						style={{
							width: '100%',
							float: 'left',
							objectFit: 'contain',
						}}
						src={`/images/services-procurement.png`}
						alt=''
						className='img-fluid'
					/>
					<div className='oct-abt-row'>
						<div className='row align-items-end'>
							<div className='col-lg-6'>
								<div
									className='main-banner-text title-hd wow fadeInUp'
									data-wow-duration='1000ms'
									style={{ marginTop: '50px' }}
								>
									<span>about us</span>
									<h1 style={{ color: 'white' }}>
										Delivery is guaranteed <span></span>
									</h1>

									<p>
										We are strategically positioned in South Africa and
										Zimbabwe.
									</p>
									<p
										style={{ color: 'white' }}
										className='bdy'
									>
										We have a fleet of vehicles ranging from small bakkies to
										large haulage trucks, all driven by dependable and
										experienced drivers who ensure that no cargo is left behind.
										We also provide Warehousing and Distribution, as well as
										Customs clearance, because we understand how important it is
										to our customers' logistics needs.
									</p>
								</div>
							</div>
							<div className='col-lg-6'>
								<div
									className='counter-cl text-center md-b-0 wow fadeInUp'
									data-wow-duration='1000ms'
									data-wow-delay='300ms'
								>
									<h2>10</h2>
									<h3>Years in the field</h3>
								</div>
							</div>
						</div>
					</div>
					<div className='oct-abt-row'>
						<div className='row align-items-center'>
							<div className='col-lg-6'>
								<div
									className='counter-cl text-center wow fadeInUp'
									data-wow-duration='1000ms'
									data-wow-delay='400ms'
								>
									<h2>16</h2>
									<h3>Vehicle Fleet</h3>
								</div>
							</div>
							<div className='col-lg-6'>
								<div
									className='text-bz pl-100 wow fadeInUp'
									data-wow-duration='1000ms'
									data-wow-delay='550ms'
								>
									<h3>Our Vision</h3>
									<p>
										Our goal is to be a market leader, focusing on strategic
										logistical solutions and being recognized for maximizing
										industry value creation.
									</p>
								</div>
							</div>
						</div>
					</div>
					<div className='oct-abt-row'>
						<div className='row align-items-center'>
							<div className='col-lg-6 order-md--1'>
								<div
									className='text-bz pr-100 wow fadeInUp'
									data-wow-duration='1000ms'
									data-wow-delay='670ms'
								>
									<h3>Our Mission</h3>
									<p>
										To be the best freight logistics company in Southern Africa,
										providing outstanding service to our clients. To provide
										excellent customer service while adhering to a strict
										delivery schedule and fair pricing. To act in the best
										interests of our clients, employees, management, and
										shareholders at all times.
									</p>
								</div>
							</div>
							<div className='col-lg-6 order-md--0'>
								<div
									className='counter-cl text-center wow fadeInUp'
									data-wow-duration='1000ms'
									data-wow-delay='800ms'
								>
									<h2>99%</h2>
									<h3>Delivery Efficiency</h3>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default About;
