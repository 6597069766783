const PageHeader = ({ pagetitle }) => {
	return (
		<div style={{ marginTop: '200px', backgroundColor: 'white' }}>
			<div className="pager-sec text-right pb-75">
				<div className="container-fluid">
					<ul>
						<li>
							<a
								href="/"
								title="">
								Home
							</a>
						</li>
						<li>
							<span>{pagetitle}</span>
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
};

export default PageHeader;
