const RequestQuote = () => {
	return (
		<div>
			<section
				className="sec-block why-choose-us slideInBottom"
				style={{ marginTop: '-200px' }}>
				<div className="fixed-bg bg8"></div>
				<h2 className="page-number">05</h2>
				<div className="container">
					<div>
						<div
							className="col-lg-7"
							// style={{
							// 	margin: 'auto',
							// 	textAlign: 'center',
							// }}
						>
							<div className="why-we-sec">
								<div
									// style={{
									// 	margin: 'auto',
									// 	textAlign: 'center',
									// }}
									className="main-banner-text title-hd wow fadeInUp"
									data-wow-delay="300ms">
									<h1>
										Request Quote<span></span>
									</h1>
									<p
									// style={{
									// 	margin: 'auto',
									// 	textAlign: 'center',
									// }}
									>
										We’ve got a lot of awards for our products and services that
										became popular in the world.
									</p>
								</div>

								<div
									className="col-lg-12"
									style={{
										marginTop: '50px',
									}}>
									<div className="input-field m-0">
										<a
											href="/contact"
											type="button"
											className="btn-default-1"
											// id="submit"
										>
											Request Quote
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
};

export default RequestQuote;
