import Footer from './global-components/footer';
import NavBar from './global-components/navbar';
import ServiceDetails from './sectional-components/serviceDetails';

const ServicePage = () => {
	return (
		<div>
			<NavBar />
			<ServiceDetails />
			<Footer />
		</div>
	);
};

export default ServicePage;
