import { useState } from 'react';
import { useLocation } from 'react-router-dom';

const Services = () => {
	const location = useLocation().pathname;

	const [values, setValues] = useState({
		procurement: true,
		freight: false,
		warehousing: false,
		customs: false,
		forwarding: false,
	});

	const handleChange = (e) => {
		console.log(e.target.id);
		// console.log(event.target.id);
		let name = `${e.target.id}`;
		console.log(name);
		setValues({
			...values,
			procurement: false,
			freight: false,
			warehousing: false,
			customs: false,
			forwarding: false,
			[name]: true,
		});
	};

	console.log(values);
	return (
		<div className='fadeInUp'>
			<section className='sec-block3 pt-5'>
				<div className='fixed-bg bg7'></div>
				<h2 className='page-number'>{location === '/services' ? '' : '03.'}</h2>
				<div className='container slideInLeft'>
					<div
						className='main-banner-text title-hd max-width-100'
						style={{ marginTop: '25px' }}
					>
						<span>our services</span>
						<h1 style={{ color: 'white' }}>
							We offer rock solid guarantees<span></span>
						</h1>
						{/* <p className="bdy">
						Your organization must be able to rely on us in order to maintain
						its own commitment to quality and service to its own consumers while
						taking service, flexibility, and cost into account. At Equitrail
						Global:
					</p> */}
					</div>
				</div>
				<div className='services-section without-carousel'>
					<div className='container'>
						<div className='row'>
							<div className='col-lg-2 col-md-4 col-sm-6'>
								<div
									id='procurement'
									onClick={handleChange}
									className='service-col'
								>
									<div className='svs-icon'>
										<img
											id='procurement'
											onClick={handleChange}
											style={{ width: '70px' }}
											src='images/icon5.svg'
											alt=''
										/>
									</div>
									<h3
										id='procurement'
										onClick={handleChange}
									>
										Procurement
									</h3>
									{/* <a
										href="/procurement"
										title=""
										className="btn-default2">
										Learn More
									</a> */}
								</div>
							</div>
							<div className='col-lg-2 col-md-4 col-sm-6'>
								<div
									id='freight'
									onClick={handleChange}
									className='service-col'
								>
									<div className='svs-icon'>
										<img
											id='freight'
											onClick={handleChange}
											style={{ width: '70px' }}
											src='images/icon6.svg'
											alt=''
										/>
									</div>
									<h3
										id='freight'
										onClick={handleChange}
									>
										Road Freight
									</h3>
									{/* <a
										href="/freight"
										title=""
										className="btn-default2">
										Learn More
									</a> */}
								</div>
							</div>
							<div className='col-lg-2 col-md-4 col-sm-6'>
								<div
									id='forwarding'
									onClick={handleChange}
									className='service-col'
								>
									<div className='svs-icon'>
										<img
											id='forwarding'
											onClick={handleChange}
											style={{ width: '70px' }}
											src='images/icon7.svg'
											alt=''
										/>
									</div>
									<h3
										id='forwarding'
										onClick={handleChange}
									>
										Freight Forwarding
									</h3>
									{/* <a
										href="/forwarding"
										title=""
										className="btn-default2">
										Learn More
									</a> */}
								</div>
							</div>
							<div className='col-lg-2 col-md-4 col-sm-6'>
								<div
									id='customs'
									onClick={handleChange}
									className='service-col'
								>
									<div className='svs-icon'>
										<img
											id='customs'
											onClick={handleChange}
											style={{ width: '70px' }}
											src='images/icon8.svg'
											alt=''
										/>
									</div>
									<h3
										id='customs'
										onClick={handleChange}
									>
										Customs Clearing Agents
									</h3>
									{/* <a
										href="/customs"
										title=""
										className="btn-default2">
										Learn More
									</a> */}
								</div>
							</div>
							<div className='col-lg-2 col-md-4	 col-sm-6'>
								<div
									id='warehousing'
									onClick={handleChange}
									className='service-col'
								>
									<div className='svs-icon'>
										<img
											id='warehousing'
											onClick={handleChange}
											style={{ width: '70px' }}
											src='images/icon9.svg'
											alt=''
										/>
									</div>
									<h3
										id='warehousing'
										onClick={handleChange}
									>
										Warehousing & Distribution
									</h3>
									{/* <a
										href="/warehousing"
										title=""
										className="btn-default2">
										Learn More
									</a> */}
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<div
				className='popup'
				style={{ height: '0%' }}
			>
				<section className='sec-block p-0'>
					<div className='fixed-bg '></div>
					<div className='page-content'>
						<div className='fixed-bg '></div>

						{values.freight && (
							<div className='container'>
								<div className='main-banner-text title-hd max-width-80'>
									<h1 style={{ paddingTop: '50px' }}>Road Freight</h1>
								</div>
								<div
									style={{
										marginTop: '-50px',
									}}
									className='service-details pb-0'
								>
									<div className='row'>
										<div className='col-lg-12'>
											<div className='service-details-content flexclass'>
												<img
													style={{
														height: '500px',
														float: 'left',
														objectFit: 'contain',
													}}
													src={`/images/services-freight.png`}
													alt=''
													className='img-fluid'
												/>
												<p
													style={{
														color: 'black',
														float: 'left',
														width: '33.33',
														paddingLeft: '50px',
														fontSize: '30px',
													}}
												>
													<br /> Courier services in: Zimbabwe – Harare,
													Bulawayo and all cities and towns surrounding and or
													along the route South Africa – Johannesburg and all
													cities surrounding and or along the route We also
													offer collection of goods upon request or
													alternatively you can deliver to our premises. <br />
												</p>
											</div>
										</div>
										{/* <div className="col-lg-3">
											<div className="sidebar">
												<div className="widget widget-help">
													<h3>How can we help you?</h3>
													<p>Don’t know where to start? We’ll help you.</p>
													<a
														href="/contact"
														title=""
														className="btn-default2">
														contact us
													</a>
												</div>
											</div>
										</div> */}
									</div>
								</div>
							</div>
						)}
						{values.procurement && (
							<div className='container'>
								<div className='main-banner-text title-hd max-width-80'>
									<h1 style={{ paddingTop: '50px' }}>
										Procurement <span></span>
									</h1>
								</div>
								<div className='service-details pb-0'>
									<div className='row'>
										<div className='col-lg-12 '>
											<div className='service-details-content flexclass'>
												<img
													style={{
														height: '500px',
														float: 'left',
														objectFit: 'contain',
													}}
													src={`/images/services-procurement.png`}
													alt=''
													className='img-fluid'
												/>
												<p
													style={{
														color: 'black',
														float: 'left',
														width: '33.33',
														paddingLeft: '50px',
														fontSize: '30px',
													}}
												>
													Our procurement process provides a cost-effective
													solution as well as process simplification. We have
													formed strategic partnerships with significant
													suppliers, which allows us to provide cost-effective,
													on-time delivery, transportation visibility, reduced
													risk, and improved operational efficiency.
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						)}
						{values.forwarding && (
							<div className='container'>
								<div className='main-banner-text title-hd max-width-80'>
									<h1 style={{ paddingTop: '50px' }}>
										Freight Forwarding <span></span>
									</h1>
								</div>
								<div className='service-details pb-0'>
									<div className='row'>
										<div className='col-lg-12'>
											<div className='service-details-content flexclass'>
												<img
													style={{
														height: '500px',
														float: 'left',
														objectFit: 'contain',
													}}
													src={`/images/services-forwarding.png`}
													alt=''
													className='img-fluid'
												/>
												<p
													style={{
														color: 'black',
														float: 'left',
														width: '33.33',
														paddingLeft: '50px',
														fontSize: '30px',
													}}
												>
													We have the capacity to handle all of your
													professional freight forwarding needs. We provide
													hassle-free border crossing services between the
													following countries: <br />
													<br />• South Africa <br />• Zimbabwe <br />• Zambia{' '}
													<br />• Mozambique
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						)}
						{values.customs && (
							<div className='container'>
								<div className='main-banner-text title-hd max-width-80'>
									<h1 style={{ paddingTop: '50px' }}>
										Customs <span></span>
									</h1>
								</div>
								<div className='service-details pb-0'>
									<div className='row'>
										<div className='col-lg-12'>
											<div className='service-details-content flexclass'>
												<img
													style={{
														height: '500px',
														float: 'left',
														objectFit: 'contain',
													}}
													src={`/images/services-customs.png`}
													alt=''
													className='img-fluid'
												/>
												<p
													style={{
														color: 'black',
														float: 'left',
														width: '33.33',
														paddingLeft: '50px',
														fontSize: '30px',
													}}
												>
													Equitrail Global is a ZIMRA and SARS-accredited
													clearing house. We have a team of professionals who
													are prepared to handle the complex technical aspects
													of customs clearance procedures for both imports and
													exports of cargo in Zimbabwe and South Africa.
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						)}
						{values.warehousing && (
							<div className='container'>
								<div className='main-banner-text title-hd max-width-80'>
									<h1 style={{ paddingTop: '50px' }}>
										Warehousing and Distribution <span></span>
									</h1>
								</div>
								<div className='service-details pb-0'>
									<div className='row'>
										<div className='col-lg-12'>
											<div className='service-details-content flexclass'>
												<img
													style={{
														height: '500px',
														float: 'left',
														objectFit: 'contain',
													}}
													src={`/images/services-warehousing.png`}
													alt=''
													className='img-fluid'
												/>
												<p
													style={{
														color: 'black',
														float: 'left',
														width: '33.33',
														paddingLeft: '50px',
														fontSize: '30px',
													}}
												>
													Our warehouse solutions are adaptable to the transport
													methods chosen by our clients. Our solution includes
													an improved warehouse design with a variety of systems
													and processes such as racking, pick and pack, and
													track and trace capabilities. Equitrail Global has
													distribution centers in South Africa and Zimbabwe.
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						)}
					</div>
				</section>
			</div>
		</div>
	);
};

export default Services;
