import React from 'react';

import { createRoot } from 'react-dom/client';
import { HashRouter, Routes, Route } from 'react-router-dom';
import HomePage from './components/homePage';
import Services from './components/servicesPage';
import ContactUs from './components/contactPage';
import AboutUs from './components/aboutPage';
import Service from './components/service';

const root = createRoot(document.getElementById('root'));
root.render(
	<HashRouter>
		<Routes>
			<Route
				path='/'
				element={<HomePage />}
			/>
			<Route
				path='about'
				element={<AboutUs />}
			/>
			<Route
				path='contact'
				element={<ContactUs />}
			/>
			<Route
				path='services'
				element={<Services />}
			/>
			<Route
				path=':service'
				element={<Service />}
			/>
		</Routes>
	</HashRouter>
);
