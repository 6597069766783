import NavBar from './global-components/navbar';
import BrandsTestimonials from './sectional-components/brandsTestimonials';
import MainBanner from './sectional-components/mainBanner';
import MiniAbout from './sectional-components/miniAbout';
import Services from './sectional-components/services';
// import Delivery from './sectional-components/deliveryDestinations';
import RequestQuote from './sectional-components/quote';
import Footer from './global-components/footer';

const HomePage = () => {
	return (
		<div>
			<NavBar />
			<MainBanner />
			<MiniAbout />

			<Services />
			<BrandsTestimonials />
			{/* <Delivery /> */}

			<RequestQuote />
			<Footer />
		</div>
	);
};

export default HomePage;
