import { useState, useRef, useEffect } from 'react';
import emailjs from '@emailjs/browser';

const GetQuote = () => {
	const [values, setValues] = useState({
		freight: '',
		weight: 0,
		message: 'Freight: \nWeight: \nFrom: \nTo:',
	});

	const form = useRef();

	const sendEmail = async (e) => {
		e.preventDefault();

		console.log('sending email');

		emailjs
			.sendForm(
				'service_0j6xb2w',
				'template_x121k96',
				form.current,
				'LszvkpDQif5UJgExd'
			)
			.then(
				(result) => {
					alert(`${result.text}: Email Sent`);
					window.location.reload();
					setValues({
						...values,
						freight: '',
						weight: 0,
						message: 'Freight: \nWeight: \n',
					});
				},
				(error) => {
					console.log(error.text);
				}
			);
	};

	const handleChange = (name) => (e) => {
		setValues({ ...values, [name]: e.target.value });
	};

	// const formString = () => {
	// 	const fullMessage =
	// 		`Freight Type: ${values.freight}\n` +
	// 		`Gross Weight: ${values.weight}\n` +
	// 		'\n' +
	// 		values.message;
	// 	console.log(fullMessage);

	// 	setValues({ ...values, message: fullMessage });
	// };

	// useEffect(() => {
	// 	formString();
	// }, []);

	return (
		<section
			style={{ marginTop: '150px' }}
			className='sec-block2'
		>
			<h2 className='page-number bottom'>01.</h2>
			<div className='fixed-bg '></div>
			<div className='container'>
				<div className='contact-us-page'>
					<div className='row'>
						<div className='col-lg-6'>
							<div className='contact-list'>
								<div className='contact-lzt'>
									<div className='contact-icon'>
										<img
											src='images/c-icon1.svg'
											alt=''
										/>
									</div>
									<div className='contact-nfo'>
										<h3>Address:</h3>
										<p>
											Equitrail Global Freight Logistics 3 Lantana Lane Edleen
											Johannesburg South Africa
										</p>
									</div>
								</div>
								<div className='contact-lzt'>
									<div className='contact-icon'>
										<img
											src='images/c-icon2.svg'
											alt=''
										/>
									</div>
									<div className='contact-nfo'>
										<h3>Hit us up:</h3>
										<span>
											+263 292 71206 <br /> +263 780 630 097
										</span>
										<span>admin@equitrailglobal.co.za</span>
									</div>
								</div>
								<div className='contact-lzt'>
									<div className='contact-icon'>
										<img
											src='images/c-icon3.svg'
											alt=''
										/>
									</div>
									<div className='contact-nfo'>
										<h3>Working Schedule:</h3>
										<p>Mon - Sat: 9 am - 6 pm, Sun: Holiday</p>
									</div>
								</div>
							</div>
						</div>
						<div className='col-lg-6'>
							<div className='contact-section-page'>
								<div
									className='main-banner-text title-hd wow fadeInUp'
									data-wow-delay='300ms'
								>
									<span>contact us</span>
									<h1>
										Request A quote <span></span>
									</h1>
									<p className='bdy'>
										You can count on us to fulfill your commitments to your
										customers on time every time.
									</p>
								</div>
								<div
									className='contact-form wow fadeInUp'
									data-wow-delay='300ms'
								>
									<form
										onSubmit={sendEmail}
										ref={form}
										id='contact-form'
									>
										<div className='response'></div>
										<div className='row'>
											<div className=' col-lg-12 '>
												<div className='input-field'>
													<input
														type='text'
														name='from_name'
														className='name'
														placeholder='Name & Surname'
													/>
												</div>
											</div>
											<div className='col-lg-6 col-md-6 col-sm-6'>
												<div className='input-field'>
													<input
														type='text'
														name='from_email'
														className='email'
														placeholder='Email'
													/>
												</div>
											</div>
											<div className='col-lg-6 col-md-6 col-sm-6'>
												<div className='input-field'>
													<input
														type='tel'
														name='from_phone'
														className='phone'
														placeholder='Phone'
													/>
												</div>
											</div>
											{/* <div className="col-lg-6 col-md-6 col-sm-6">
												<div className="input-field">
													<input
														onChange={handleChange('freight')}
														type="text"
														name="freight Type"
														className="email"
														placeholder="Freight Type"
													/>
												</div>
											</div>
											<div className="col-lg-6 col-md-6 col-sm-6">
												<div className="input-field">
													<input
														onChange={handleChange('weight')}
														type="number"
														name="weight"
														className="phone"
														placeholder="Weight"
													/>
												</div>
											</div> */}
											<div className='col-lg-12'>
												<div className='input-field'>
													<textarea
														style={{ height: '200px' }}
														onChange={handleChange('message')}
														name='message'
														value={values.message}
														placeholder='Message'
													></textarea>
												</div>
											</div>
											<div className='col-lg-12'>
												<div className='input-field m-0'>
													<button
														onClick={sendEmail}
														type='button'
														className='btn-default-1'
														id='submit'
													>
														Make Request
													</button>
												</div>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default GetQuote;
